import React, {Component} from 'react';

class BoxView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="box-content">
                    <div className="title">Our Vision</div>
                    <p>We aim to partner with our clients for the long term, offering a considered personal approach to
                        your investment and protection needs.</p>
                    <p>We want to save you time and create the lifestyle you desire.</p>
                    <div className="dividers dividers-bc-v4"></div>
                </div>
            </div>

        )

    }
}

export default BoxView;