import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {EMAIL_ADDRESS, PHONE_NUMBER, POSTAL_ADDRESS} from '../../../../constants';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i><Link to={{pathname: `mailto:${EMAIL_ADDRESS}`}} title="">{EMAIL_ADDRESS}</Link></li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i><Link to={{pathname: `tel:${PHONE_NUMBER.replace(/ /g, '')}`}} title="">{PHONE_NUMBER}</Link></li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link to="/contact-us" title="">{POSTAL_ADDRESS}</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;
