const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
    {
        id: 2,
        name: 'About Us',
        linkmenu: '/about-us',
    },
    {
        id: 3,
        name: 'Services',
        linkmenu: '/services',
    },
    {
        id: 4,
        name: 'Contact',
        linkmenu: '/contact-us',
    }
]

export default menus;
