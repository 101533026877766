import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <img src="/images/logos/Audit-Shield-Logo.webp" style={{height: "100px"}} />
                <img src="/images/logos/CPA-Logo.png" />
                <img src="/images/logos/Tax-Practitioners-Board-Perth.png" />
                <img src="/images/logos/xero-platinum-partner-badge-RGB.webp" />
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;