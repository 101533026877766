import React, {Component} from 'react';
import {Link} from "react-router-dom";

class MainServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: 'What We Can Offer You',
                    description: '',
                }
            ],
            imagebox: [
                {
                    id: '1',
                    imgsrc: 'images/photos/tax1.jpeg',
                    title: 'Accounting & Compliance',
                    description: '<p>The adding machines and neck-ties may be gone, but the nitty gritty of quarterly and annual lodgements remain. While we understand gathering tax information can seem time-consuming and low priority, we also know it doesn’t have to be. Call us and let RNR take your pain away. Our team has the technical knowledge and the friendly face, we keep pace with the legislation, and ask you the relevant questions to maximise your deductions and find innovative solutions. Phone, skype, email us, we can come to you, or drop in and meet with us; whichever fits your lifestyle.</p>' +
                        '<br>' +
                        '<ul>' +
                        '<li>Tax</li>' +
                        '<li>Accounting</li>' +
                        '<li>BAS / IAS / PAYG Instalments</li>' +
                        '<li>ASIC Corporate Secretarial Services</li>' +
                        '<li>ATO Correspondence and Liaison</li>' +
                        '</ul>' +
                        '<br>' +
                        '<p>Most of us prefer refunds, but if you are silently dreading a payable, you need to know we can prepare now and postpone lodgement until the due-date. Have peace of mind knowing your actual situation, plus time to budget.</p>'
                },
                {
                    id: '2',
                    imgsrc: 'images/photos/tax2.jpeg',
                    title: 'Bookkeeping & Finance',
                    description: '<p>When running your own business, you’ll want to keep your mind on the job. You won’t want to waste time crunching the numbers and keeping the books when you could spend time doing what you love. Let RNR support you: lean on the RNR in-house bookkeeping staff, let us analyse the raw data, suggest strategies, or ask us how we can help with a range of other advice and services:</p>' +
                        '<br/>' +
                        '<ul>' +
                        '<li>Platform Shifts / Software Integration</li>' +
                        '<li>Seconded Bookkeeper offerings</li>' +
                        '<li>BAS / Payroll</li>' +
                        '<li>Platforms & Apps / Add-ons</li>' +
                        '<li>Online Stores</li>' +
                        '<li>Budgeting</li>' +
                        '<li>Cashflow / Forecasting</li>' +
                        '</ul>' +
                        '<br/>' +
                        '<p>As Xero partners we recommend beautiful, cloud-based accounting. Xero is a simple, yet sophisticated accounting system very much suited to SME business. See your balances and cashflow in real-time, manage payroll, single-touch compliance, make automated bank reconciliations, pay bills, access anywhere, and have the potential for real-time advice based on up to the moment figures with minimal input. If your bookkeeping is not cloud-based, talk to us now about how it will benefit you, and how we can help you make the move.</p>'
                },
                {
                    id: '3',
                    imgsrc: 'images/photos/tax3.jpeg',
                    title: 'Advisory & Business Services',
                    description: '<p>Successful business is a marathon, not a sprint. Build for the long term with a strong vision and sound plans, paced by our relevant and timely advice. What stage is your business at? Whether you’re opening the doors, facing challenges, wanting to expand or diversify, moving online, needing to find funds, or looking to buy, sell, or keep it in the family, RNR can guide you through it all:</p>' +
                        '<br />' +
                        '<ul>' +
                        '<li>Management Accounting / Quarterly Reporting</li>' +
                        '<li>Tax Planning</li>' +
                        '<li>Structuring</li>' +
                        '<li>Business Systems and Efficiency</li>' +
                        '<li>Succession Planning</li>' +
                        '<li>Valuations & Business Sales</li>' +
                        '<li>Due Diligence & Business Purchase</li>' +
                        '<li>R&D Tax Incentive</li>' +
                        '<li>Export Development Grants</li>' +
                        '<li>ATO Audits / Dispute Resolution</li>' +
                        '</ul>' +
                        '<br />' +
                        '<p>With annual compliance as a jumping-off point, RNR offers a range of advisory services to help your business define and achieve its short and long-term goals. We help clients ride the daily bumps and challenges to grow their business. We take a partnership approach with our clients and truly believe that your success is our success.</p>'
                },
            ]
        }
    }

    render() {
        return (
            <section className="flat-row pd-services-post">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {
                                this.state.titleServices.map(data => (
                                    <div className="title-section center s1" key={data.id}>
                                        <h2>{data.title} </h2>
                                        <p className="sub-title-section">{data.description} </p>
                                    </div>
                                ))
                            }
                            <div className="dividers dividers-imagebox"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wrap-imagebox-grid">
                                {
                                    this.state.imagebox.map(data => (
                                        <div className="flat-imagebox services-grid item" key={data.id}>
                                            <div className="flat-imagebox-inner">
                                                <div className="flat-imagebox-image">
                                                    <img src={data.imgsrc} alt="img"/>
                                                </div>
                                                <div className="flat-imagebox-header">
                                                    <h3 className="flat-imagebox-title">
                                                        <Link to="#">{data.title}</Link>
                                                    </h3>
                                                </div>
                                                <div className="flat-imagebox-content">
                                                    <div className="flat-imagebox-desc"
                                                         dangerouslySetInnerHTML={{__html: data.description}}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        );
    }
}

export default MainServices;