import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Header, TopBar, Footer, Loader} from '../layouts/general';
import Overview from "../layouts/aboutus/Overview";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            dataBanner: [
                {
                    id: 1,
                    classename01: 'flat-title-page parallax parallax7 style3',
                    classename02: 'container wrap-title-page ',
                    links: '/privacy-policy',
                    names: 'Privacy Policy'
                }
            ]
        }
    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader/>
                    <TopBar/>
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Privacy Policy</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        <li><Link to="/" title="">Home<i className="fa fa-angle-right"
                                                                         aria-hidden="true"></i></Link></li>
                                        <li><Link to="/privacy-policy" title="">Privacy Policy</Link></li>
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="flat-row pd-about-post">
                        <div className="container">
                            <div className="row flat-tabs" data-effect="fadeIn">
                                <h1>1. Introduction</h1>

                                <p>In this Privacy Policy, 'R&amp;R Partners Accountants and Advisors', 'we', 'us' and
                                    'our'
                                    refers to any entity owned or controlled by R&amp;R Partners Accountants and
                                    Advisors. At
                                    R&amp;R Partners Accountants and Advisors, we recognise the importance of protecting
                                    and
                                    maintaining your personal information. We appreciate that you may have concerns
                                    about your
                                    privacy and about the security, accuracy and confidentiality of your personal
                                    information.
                                    R&amp;R Partners Accountants and Advisors is committed to providing you with the
                                    highest
                                    quality professional service whilst also protecting your privacy. R&amp;R Partners
                                    Accountants and Advisors abides by the Australian Privacy Principles established
                                    under the
                                    Privacy Amendment (Enhancing Privacy Protection) Act 2012 and the Privacy Act 1988
                                    (Cth)
                                    (Privacy Act). This Privacy Policy outlines how R&amp;R Partners Accountants and
                                    Advisors
                                    handles personal information. This Privacy Policy does not apply to personal
                                    information
                                    collected by R&amp;R Partners Accountants and Advisors that is exempted under the
                                    Privacy
                                    Act, for example employee records. R&amp;R Partners Accountants and Advisors may
                                    modify this
                                    Privacy Policy from time to time to reflect its current privacy practices.</p>


                                <h1>2. Transparent management of personal information</h1>

                                <p>The R&amp;R Partners Accountants and Advisors Privacy Policy is available on our
                                    website
                                    or may be obtained by contacting the office. If you have any queries or complaints
                                    about
                                    R&amp;R Partners Accountants and Advisors' compliance with the Australian Privacy
                                    Principles, please contact us using the 'Contact Us' information shown below. If you
                                    believe that R&amp;R Partners Accountants and Advisors has breached the Australian
                                    Privacy Principles, please contact us in writing. We will respond to you within 30
                                    days.
                                    If you are not satisfied with our response, you may take your complaint to the
                                    Office of
                                    the Australian Information Commissioner.</p>


                                <h1>3. Collection of solicited personal information</h1>
                                <p>Our ability to provide you with a comprehensive service is dependent on us obtaining
                                    certain personal information. This might include:</p>
                                <ul className="list">
                                    <li>name and contact details</li>
                                    <li>date of birth and gender</li>
                                    <li>bank account details</li>
                                    <li>employment details and employment history</li>
                                    <li>current and prospective financial circumstances, including assets and
                                        liabilities,
                                        income, expenditure, investments, insurance cover and superannuation
                                    </li>
                                    <li>documentation used for identification and verification purposes. Personal
                                        information includes 'sensitive information' (as defined in the Privacy Act)
                                        such as
                                        your Tax File Number, professional memberships and health information.
                                    </li>
                                </ul>


                                <h1>4. How personal information is collected</h1>
                                <p>It may be necessary for R&amp;R Partners Accountants and Advisors to collect your
                                    personal information from a third party. For example, we may collect your personal
                                    information from your employer where they are a client of ours, from your personal
                                    representative, another R&amp;R Partners Accountants and Advisors member firm or a
                                    publicly available record. We may also collect personal information about you from
                                    your
                                    use of our websites and information you provide to us through contact mailboxes or
                                    through the registration process on our websites. R&amp;R Partners Accountants and
                                    Advisors collects personal information by lawful and fair means and not in an
                                    unreasonably intrusive way. At or before the time (or if that is not practicable as
                                    soon
                                    as practicable after) R&amp;R Partners Accountants and Advisors collects personal
                                    information from you, R&amp;R Partners Accountants and Advisors will take reasonable
                                    steps to ensure that you are aware of:</p>
                                <ul className="list">
                                <li>our identity and how to contact us;</li>
                                    <li>the fact that you are able to gain access to your personal information held by
                                        us;
                                    </li>
                                    <li>the purposes for which the personal information is collected;</li>
                                    <li>to whom we usually disclose personal information of that kind (if at all);</li>
                                    <li>any law that requires the particular personal information to be collected; and
                                    </li>
                                    <li>the main consequences (if any) for you if all or part of your personal
                                        information
                                        is not provided. Where R&amp;R Partners Accountants and Advisors collects
                                        personal
                                        information about you from someone else, we will take reasonable steps to ensure
                                        that you are or have been made aware of the matters listed above except to the
                                        extent that making you aware of the matters would pose a serious threat to the
                                        life
                                        or health of any individual.
                                    </li>
                                </ul>


                                <h1>5. Holding personal information</h1>
                                <p>R&amp;R Partners Accountants and Advisors holds personal information in hard copy and
                                    electronic formats.</p>

                                <h1>6. Purpose for collecting, holding, using and disclosing personal information</h1>
                                <p>R&amp;R Partners Accountants and Advisors may collect, hold, use or disclose personal
                                    information:</p>
                                <ul className="list">
                                    <li>to provide professional services</li>
                                    <li>to manage any conflict of interest or independence (including auditor
                                        independence)
                                        obligations or situations
                                    </li>
                                    <li>as part of an actual (or proposed) acquisition, disposition, merger or de-merger
                                        of
                                        a business (including R&amp;R Partners Accountants and Advisors' business) or
                                        entering into an alliance, joint venture or referral arrangement or
                                    </li>
                                    <li>for any other business related purposes. The types of third parties to whom we
                                        may
                                        disclose your personal information include:
                                    </li>
                                    <li>as part of an engagement, if you are a customer, an employee, a contractor or
                                        supplier of services to one of our clients, then we may disclose your personal
                                        information as part of providing services to that client
                                    </li>
                                    <li>as part of an actual (or proposed) acquisition, disposition, merger or de-merger
                                        of
                                        a business (including R&amp;R Partners Accountants and Advisors' business) or to
                                        enter into an alliance, joint venture or referral arrangement or
                                    </li>
                                    <li>government or regulatory bodies or agencies, as part of an engagement or
                                        otherwise,
                                        (for example, the Australian Taxation Office).
                                    </li>
                                </ul>


                                <h1>7. Use or disclosure of personal information</h1>

                                <p>R&amp;R Partners Accountants and Advisors will not use or disclose your personal
                                    information that we collect other than for the primary purpose for which it is
                                    collected, unless you would reasonably expect us to use or disclose the information
                                    for
                                    a secondary purpose or your consent has been obtained to use the information for
                                    additional purposes. Where R&amp;R Partners Accountants and Advisors has collected
                                    personal information from you, we may use this information for the purpose of direct
                                    marketing, to conduct surveys, to notify of seminars and other events, to seek your
                                    feedback and for any other reasonable business-related purposes.</p>


                                <h1>8. Privacy on our website</h1>

                                <h2>8.1 Automatic collection of personal information</h2>

                                <p>Cookies, web beacons and other technologies are used by R&amp;R Partners Accountants
                                    and
                                    Advisors and its service providers on some R&amp;R Partners Accountants and Advisors
                                    websites and through email to automatically collect certain types of information.
                                    The
                                    collection of this information allows us to customise your online experience,
                                    improve
                                    the performance, usability and effectiveness of R&amp;R Partners Accountants and
                                    Advisors' online presence and to measure the effectiveness of our marketing
                                    activities.</p>

                                <h2>8.2 IP address</h2>

                                <p>An IP address is a number assigned to your computer whenever you access the internet.
                                    It
                                    allows computers and servers to recognise and communicate with one another. Public
                                    IP
                                    addresses from which visitors appear to originate may be recorded for IT security
                                    and
                                    system diagnostic purposes. This information may also be used in aggregate form to
                                    conduct web site trend and performance analysis.</p>

                                <h2>8.3 Cookies</h2>

                                <p>Cookies may be placed on your computer or internet-enabled device whenever you visit
                                    us
                                    online. This allows the site to remember your computer or device and serves a number
                                    of
                                    purposes. Although most browsers automatically accept cookies, you can choose
                                    whether or
                                    not to accept cookies via your browser's settings (often found in your browser's
                                    Tools
                                    or Preferences menu). You may also delete cookies from your device at any time.
                                    However,
                                    please be aware that if you do not accept cookies, you may not be able to fully
                                    experience some of our web sites' features. Cookies by themselves do not tell us
                                    your
                                    email address or otherwise identify you personally. In our analytical reports, we
                                    may
                                    obtain other identifiers including public IP addresses, but this is for the purpose
                                    of
                                    identifying the number of unique visitors to our web sites and geographic origin of
                                    visitor trends, and not to identify individual visitors.</p>

                                <h2>8.4 Google Analytics</h2>

                                <p>R&amp;R Partners Accountants and Advisors uses Google Analytics. To provide website
                                    visitors with more choice on how their data is collected by Google Analytics, Google
                                    have developed the Google Analytics Opt-out Browser Add-on. The add-on communicates
                                    with
                                    the Google Analytics JavaScript (ga.js) to indicate that information about the
                                    website
                                    visit should not be sent to Google Analytics. The Google Analytics Opt-out Browser
                                    Add-on does not prevent information from being sent to the website itself or to
                                    other
                                    web analytics services.</p>

                                <h2>8.5 Web beacons</h2>

                                <p>A web beacon is a small image file on a web page that can be used to collect certain
                                    information from your computer, such as an IP address, the time the content was
                                    viewed,
                                    a browser type, and the existence of cookies previously set by the same server.
                                    R&amp;R
                                    Partners Accountants and Advisors or its service providers may use web beacons to
                                    track
                                    the effectiveness of third party web sites that provide us with recruiting or
                                    marketing
                                    services or to gather aggregate visitor statistics and manage cookies. You have the
                                    option to render some web beacons unusable by rejecting their associated cookies.
                                    The
                                    web beacon may still record an anonymous visit from your IP address but cookie
                                    information will not be recorded. In some of our newsletters and other
                                    communications,
                                    we may monitor recipient actions such as email open rates through embedded links
                                    within
                                    the messages. We collect this information to gauge user interest and to enhance
                                    future
                                    user experiences.</p>

                                <h2>8.6 Location-based tools</h2>

                                <p>R&amp;R Partners Accountants and Advisors may collect and use the geographical
                                    location
                                    of your computer or mobile device. This location data is collected for the purpose
                                    of
                                    providing you with information regarding services which we believe may be of
                                    interest to
                                    you based on your geographic location, and to improve our location-based products
                                    and
                                    services.</p>

                                <h2>8.7 Social media widgets and applications</h2>

                                <p>R&amp;R Partners Accountants and Advisors web sites may include functionality to
                                    enable
                                    sharing via third party social media applications, such as the Facebook Like button
                                    and
                                    Linked In widget. These social media applications may collect and use information
                                    regarding your use of R&amp;R Partners Accountants and Advisors web sites. Any
                                    personal
                                    information that you provide via such social media applications may be collected and
                                    used by other members of that social media application and such interactions are
                                    governed by the privacy policies of the companies that provide the application. We
                                    do
                                    not have control over, or responsibility for, those companies or their use of your
                                    information. In addition, R&amp;R Partners Accountants and Advisors web sites may
                                    host
                                    blogs, forums, crowd-sourcing and other applications or services (collectively
                                    "social
                                    media features"). The purpose of social media features is to facilitate the sharing
                                    of
                                    knowledge and content. Any personal information that you provide on any R&amp;R
                                    Partners
                                    Accountants and Advisors social media feature may be shared with other users of that
                                    social media feature (unless otherwise stated at the point of collection), over whom
                                    we
                                    may have limited or no control.</p>

                                <h2>8.8 Links to third party websites</h2>

                                <p>R&amp;R Partners Accountants and Advisors' websites may contain links to third
                                    parties'
                                    websites, including sites maintained by other R&amp;R Partners Accountants and
                                    Advisors
                                    member firms. Those other websites are not subject to our privacy policy and
                                    procedures.
                                    You will need to review those websites to view a copy of their privacy policy.
                                    R&amp;R
                                    Partners Accountants and Advisors also does not endorse, approve or recommend the
                                    services or products provided on third party websites.</p>


                                <h1>9. Security</h1>

                                <p>R&amp;R Partners Accountants and Advisors will take reasonable steps to keep
                                    collected
                                    personal information secure and protected from unauthorised disclosure or other
                                    access,
                                    misuse, interference and loss. There are, of course, inherent risks in the storage
                                    and
                                    transmission of information electronically (including over the internet) which are
                                    not
                                    practicable to eliminate entirely.</p>


                                <h1>10. Quality</h1>

                                <p>R&amp;R Partners Accountants and Advisors will take reasonable steps to ensure the
                                    personal information we collect, use or disclose is accurate, complete and
                                    up-to-date.</p>


                                <h1>11. Corrections and Access</h1>

                                <p>R&amp;R Partners Accountants and Advisors will provide access to you regarding your
                                    personal information on your request except where we are permitted by law to
                                    withhold
                                    such access. On request from you, we will take reasonable steps to correct your
                                    personal
                                    information that we hold, and we will respond to your request within 30 days of
                                    receipt
                                    of such request.</p>


                                <h1>12. Destruction</h1>

                                <p>Where R&amp;R Partners Accountants and Advisors determines, acting reasonably, that
                                    your
                                    personal information is no longer needed for our function or the services we
                                    provide, we
                                    will permanently delete or de-identify that information. Where R&amp;R Partners
                                    Accountants and Advisors receives unsolicited personal information, it will destroy
                                    or
                                    de-identify that unsolicited personal information.</p>


                                <h1>13. Complaints</h1>

                                <p>If you are not satisfied with the outcome of your complaint, you can refer your
                                    complaint
                                    to the Office of the Australian Information Commissioner.</p>


                                <h1>14. How to contact us</h1>

                                <p>If you have a query in relation to this Privacy Policy or you would like to notify
                                    R&amp;R Partners Accountants and Advisors that you no longer wish to receive
                                    marketing material from us, access or correct your personal information or to make a
                                    complaint about R&amp;R Partners Accountants and Advisors' handling of your personal
                                    information, please contact R&amp;R Partners Accountants and Advisors.</p>
                            </div>
                        </div>
                    </section>

                    <Footer/>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
