import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services">
                <ul className="one-half first">
                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                    <li><Link to="/about-us" onClick={() => {window.location.href="/about-us"}} title="">About Us</Link></li>
                    <li><Link to="/services" onClick={() => {window.location.href="/services"}} title="">Services</Link></li>
                    <li><Link to="/contact-us" onClick={() => {window.location.href="/contact-us"}} title="">Contact Us</Link></li>
                </ul>
                {/* <!-- /.one-half --> */}
                <ul className="one-half">
                    <li><Link to="/privacy-policy" onClick={() => {window.location.href="/privacy-policy"}} title="">Privacy Policy</Link></li>
                    {/* <li><Link to="/terms-and-conditions" onClick={() => {window.location.href="/terms-and-conditions"}} title="">Terms &amp; Conditions</Link></li> */}
                </ul>
                {/* <!-- /.one-half --> */}
            </div>
        /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;
