import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    srcimg: 'images/photos/about.jpeg',
                    title: 'About Us',
                    description: '',
                    url: '/about-us'
                },
                {
                    id: 2,
                    srcimg: 'images/photos/services.jpeg',
                    title: 'Services',
                    description: '',
                    url: '/services'
                },
                {
                    id: 3,
                    srcimg: 'images/photos/contact.jpeg',
                    title: 'Contact Us',
                    description: '',
                    url: '/contact-us'
                },
            ]
        }
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="imagebox-item">
                                <div className="imagebox style1">
                                    <div className="imagebox-image">
                                        <img src={data.srcimg} alt="financial" />
                                    </div>
                                    {/* <!-- /.imagebox-image --> */}

                                    <div className="imagebox-title">
                                        <h3><Link to={data.url} onClick={() => {window.location.href=data.url}} title="">{data.title}</Link></h3>
                                    </div>
                                    {/* <!-- /.iamgebox-title --> */}
                                    <div className="imagebox-content">
                                        <div className="imagebox-desc">{data.description}</div>
                                        <div className="imagebox-button">
                                            <Link to={data.url} onClick={() => {window.location.href=data.url}} title="">read more</Link>
                                        </div>
                                    </div>
                                    {/* <!-- /.imagebox-content --> */}
                                </div>
                                {/* <!-- /.imagebox --> */}
                            </div>
                            {/* <!-- /.imagebox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs;
