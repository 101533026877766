import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { PHONE_NUMBER, EMAIL_ADDRESS } from '../../../constants'

class TopBar extends Component {
    render() {
        return (
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="flat-infomation">
                                <li className="phone">Call us: <Link to={{pathname: `tel:${PHONE_NUMBER.replace(/ /g, '')}`}} title="phone">{PHONE_NUMBER}</Link></li>
                                <li className="email">Email: <Link to={{pathname: `mailto:${EMAIL_ADDRESS}`}} title="email">{EMAIL_ADDRESS}</Link></li>
                            </ul>
                            <div className="flat-questions">
                                <Link to="/contact-us" onClick={() => {window.location.href="/contact-us"}} title="Contact Us" className="questions">Have any questions?</Link>
                                <Link to="/contact-us" onClick={() => {window.location.href="/contact-us"}} title="Contact Us" className="appointment">GET AN APPOINTMENT</Link>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
		    </div>
        );
    }
}

export default TopBar;
