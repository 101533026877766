import Home from './Home'
import AboutUs from './AboutUs'
import Services from './Services'
import Contact from './Contact'
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const routes = [
    { path: '/', component: Home},
    { path: '/about-us', component: AboutUs},
    { path: '/services', component: Services},
    { path: '/contact-us', component: Contact},
    { path: '/privacy-policy', component: PrivacyPolicy},
    { path: '/terms-and-conditions', component: TermsAndConditions},
]

export default routes;
